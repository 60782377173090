<template>
  <div>
    <v-banner> TAMBAH DATA UNIT KERJA </v-banner>

    <div class="mt-5">
      <v-form ref="form">
        <v-row class="mb-n2" dense>
          <v-col cols="12" md="2" class="mt-2"> Unit Kerja </v-col>

          <v-col cols="12" md="10">
            <v-text-field v-model="name" outlined dense></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mb-n2" dense>
          <v-col cols="12" md="2" class="mt-2"> Kode Unit Kerja </v-col>

          <v-col cols="12" md="2">
            <v-text-field v-model="name" outlined dense></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mb-n2" dense>
          <v-col cols="12" md="2" class="mt-2"> Jenis Unit Kerja </v-col>

          <v-col cols="12" md="5">
            <v-select v-model="select" outlined dense></v-select>
          </v-col>
        </v-row>

        <v-row class="mb-n2" dense>
          <v-col cols="12" md="2" class="mt-2"> Tipe </v-col>

          <v-col cols="12" md="2">
            <v-select v-model="select" outlined dense></v-select>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" md="2" class="mt-2"> Induk Unit Kerja </v-col>

          <v-col cols="12" md="1" class="mt-n3">
            <v-switch v-model="switch1"></v-switch>
          </v-col>

          <v-col cols="12" md="5">
            <v-select v-model="select" outlined dense></v-select>
          </v-col>
        </v-row>

        <v-row class="mb-n2" dense>
          <v-col cols="12" md="2" class="mt-2"> Alamat </v-col>

          <v-col cols="12" md="10">
            <v-textarea outlined></v-textarea>
            <v-row dense>
              <v-col cols="12" md="3">
                <v-select
                  v-model="select"
                  outlined
                  dense
                  persistent-hint
                  hint="Dusun / Lingkungan"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="name"
                  outlined
                  dense
                  readonly
                  filled
                  persistent-hint
                  hint="Desa / Kelurahan"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="name"
                  outlined
                  dense
                  readonly
                  filled
                  persistent-hint
                  hint="Kecamatan"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="mb-n2" dense>
          <v-col cols="12" md="2" class="mt-2"> Nomor Telpon </v-col>

          <v-col cols="12" md="5">
            <v-text-field v-model="name" outlined dense></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mb-n2" dense>
          <v-col cols="12" md="2" class="mt-2"> Email </v-col>

          <v-col cols="12" md="10">
            <v-text-field v-model="name" outlined dense></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" md="2" class="mt-2"> Pimpinan </v-col>

          <v-col cols="12" md="1" class="mt-n3">
            <v-switch v-model="switch1"></v-switch>
          </v-col>

          <v-col cols="12" md="5">
            <v-text-field v-model="name" outlined dense></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mb-n2" dense>
          <v-col cols="12" md="2" class="mt-2"> Satatus </v-col>

          <v-col cols="12" md="3">
            <v-select v-model="select" outlined dense></v-select>
          </v-col>
        </v-row>

        <v-row class="mb-n2" dense>
          <v-col cols="12" md="2" class="mt-2"> Keterangan </v-col>

          <v-col cols="12" md="3">
            <v-text-field v-model="name" outlined dense></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" md="2" class="mt-2"> Foto </v-col>

          <v-col cols="12" md="5">
            <v-file-input
              label="Pilih Foto"
              prepend-icon="mdi-camera"
              dense
            ></v-file-input>
          </v-col>
        </v-row>

        <v-divider class="my-5"></v-divider>

        <v-btn
          color="success"
          class="mr-4"
        >
          Simpan
        </v-btn>

        <v-btn color="warning" class="mr-4" @click="reset"> Reset </v-btn>

        <v-btn color="secondary" @click="resetValidation">
          Batal
        </v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
};
</script>